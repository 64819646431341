.button {
    @apply ease-in-out inline-block rounded-full;

    transition: background-color 250ms, color 250ms;
}

.buttonSmall {
    @apply px-4 py-2 text-xs leading-tight;
}

.buttonMedium {
    @apply px-6 py-3 text-base leading-tight;
}

.buttonLarge {
    @apply px-8 py-4 text-lg leading-none;
}

.buttonPrimary, .buttonSecondary, .buttonSuperPrimary, .flexButtonAmazon, .flexButtonApple, .flexButtonFacebook, .flexButtonGoogle, .flexButtonNuptuality, .flexButtonPinterest, .flexButtonSnapchat, .flexButtonSpotify {
    @apply cursor-pointer;
}

.buttonPrimaryDisabled, .buttonSecondaryDisabled, .buttonSuperPrimaryDisabled {
    @apply cursor-default;
}

.flexButton {
    @apply ease-in-out inline-flex items-center justify-center rounded-full;

    transition: background-color 250ms, transform 250ms;
}

.flexButtonText {
    @apply ease-in-out;

    transition: color 250ms;
}

.flexButtonAmazon, .flexButtonPinterest, .flexButtonSnapchat, .flexButtonSpotify {
    @apply border border-transparent w-full;
}

.flexButtonAmazonText, .flexButtonPinterestText, .flexButtonSnapchatText, .flexButtonSpotifyText {
    @apply ml-2;
}

.flexButtonAmazon:hover {
    background-color: #111111;
    transform: scale(0.9);
}

.flexButtonApple {
    @apply bg-black border border-transparent;

    transition: transform 250ms;
    width: 240px;
}

.flexButtonApple:hover {
    background-color: #111111;
    transform: scale(0.9);
}

.flexButtonAppleText {
    @apply ml-2 text-white;
}

.flexButtonFacebook {
    @apply border border-transparent;

    background-color: #1877F2;
    transition: transform 250ms;
    width: 240px;
}

.flexButtonFacebook:hover {
    background-color: #2780F3;
    transform: scale(0.9);
}

.flexButtonFacebookText {
    @apply ml-2 text-white;
}

.flexButtonGoogle {
    @apply bg-white border border-transparent;

    transition: transform 250ms;
    width: 240px;
}

.flexButtonGoogle:hover {
    background-color: #EEEEEE;
    transform: scale(0.9);
}

.flexButtonGoogleText {
    @apply ml-2;

    color: #767676;
}

.flexButtonNuptuality {
    @apply bg-purple-500 border border-transparent;

    transition: transform 250ms;
    width: 240px;
}

.flexButtonNuptuality:hover {
    @apply bg-purple-400;

    transform: scale(0.9);
}

.flexButtonNuptualityText {
    @apply ml-2 text-gray-50;
}

@media (prefers-color-scheme: dark) {
    .buttonPrimary {
        @apply border border-purple-300 text-purple-300;
    }

    .buttonPrimary:hover {
        @apply bg-dark-pressablePressed border border-purple-300 text-gray-50;
    }

    .buttonPrimaryDisabled {
        @apply bg-transparent border border-purpleGray-700 text-gray-500;
    }

    .buttonSecondary {
        @apply border border-transparent text-purple-300;
    }

    .buttonSecondary:hover {
        @apply bg-dark-pressablePressed border border-transparent text-purple-300;
    }

    .buttonSecondaryDisabled {
        @apply border border-transparent text-gray-500;
    }

    .buttonSuperPrimary {
        @apply bg-purple-500 border border-transparent text-gray-50;
    }

    .buttonSuperPrimary:hover {
        @apply bg-dark-pressablePressed border border-transparent text-purple-300;
    }

    .buttonSuperPrimaryDisabled {
        @apply bg-purpleGray-700 border border-transparent text-gray-500;
    }

    .flexButtonAmazon {
        @apply bg-black;
    }

    .flexButtonAmazon:hover {
        background-color: #111111;
    }

    .flexButtonAmazonText {
        @apply text-white;
    }


    .flexButtonPinterest {
        background-color: #E60023;
    }

    .flexButtonPinterest:hover {
        background-color: #E92442;
    }

    .flexButtonPinterestText {
        @apply text-white;
    }

.flexButtonSnapchat {
        @apply bg-black;
    }

    .flexButtonSnapchat:hover {
        background-color: #111111;
    }

    .flexButtonSnapchatText {
        @apply text-white;
    }

    .flexButtonSpotify {
        @apply bg-black;
    }

    .flexButtonSpotify:hover {
        background-color: #111111;
    }

    .flexButtonSpotifyText {
        color: #1ED760;
    }
}

@media (prefers-color-scheme: light) {
    .buttonPrimary {
        @apply border border-cyan-500 text-cyan-500;
    }

    .buttonPrimary:hover {
        @apply bg-light-pressablePressed border border-cyan-500 text-gray-50;
    }

    .buttonPrimaryDisabled {
        @apply bg-transparent border border-blueGray-100 text-gray-500;
    }

    .buttonSecondary {
        @apply border border-transparent text-cyan-500;
    }

    .buttonSecondary:hover {
        @apply bg-light-pressablePressed border border-transparent text-cyan-500;
    }

    .buttonSecondaryDisabled {
        @apply border border-transparent text-gray-500;
    }

    .buttonSuperPrimary {
        @apply bg-cyan-500 border border-cyan-500 text-gray-50;
    }

    .buttonSuperPrimary:hover {
        @apply bg-light-pressablePressed border border-transparent text-cyan-500;
    }

    .buttonSuperPrimaryDisabled {
        @apply bg-blueGray-100 border border-transparent text-gray-500;
    }

    .flexButtonAmazon {
        background-color: #F9D84C;
    }

    .flexButtonAmazon:hover {
        background-color: #F5D347;
    }

    .flexButtonAmazonText {
        @apply text-black;
    }

    .flexButtonPinterest {
        @apply bg-white;
    }

    .flexButtonPinterest:hover {
        background-color: #EEEEEE;
    }

    .flexButtonPinterestText {
        color: #E60023;
    }

    .flexButtonSnapchat {
        background-color: #FFFC00;
    }

    .flexButtonSnapchat:hover {
        background-color: #FFFD6C;
    }

    .flexButtonSnapchatText {
        @apply text-black;
    }

    .flexButtonSpotify {
        background-color: #1DB954;
    }

    .flexButtonSpotify:hover {
        background-color: #2DBE60;
    }

    .flexButtonSpotifyText {
        @apply text-white;
    }
}