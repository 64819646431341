@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Outfit&display=swap');

@responsive {
    .h2 {
        @apply text-3xl font-display leading-normal;
    }
    .h3 {
        @apply text-h3 font-display leading-normal;
    }
    .h4 {
        @apply text-h4 font-display leading-normal;
    }
    .h5 {
        @apply text-h5 font-display leading-normal;
    }
    .h6 {
        @apply text-lg font-display leading-normal;
    }
    .h7 {
        @apply text-base font-display leading-normal;
    }
    .h9 {
        @apply text-xs font-display leading-normal;
    }

    .text-xxxx-large-light {
        @apply text-4xl font-light font-body leading-none;
    }
    .text-xxxx-large-regular {
        @apply text-4xl font-normal font-body leading-none;
    }
    .text-xxxx-large-semibold {
        @apply text-4xl font-semibold font-body leading-none;
    }
    .text-xxxx-large-bold {
        @apply text-4xl font-bold font-body leading-none;
    }

    .text-xxx-large-light {
        @apply text-3xl font-light font-body leading-none;
    }
    .text-xxx-large-regular {
        @apply text-3xl font-normal font-body leading-none;
    }
    .text-xxx-large-semibold {
        @apply text-3xl font-semibold font-body leading-none;
    }
    .text-xxx-large-bold {
        @apply text-3xl font-bold font-body leading-none;
    }

    .text-xx-large-light {
        @apply text-2xl font-light font-body leading-none;
    }
    .text-xx-large-regular {
        @apply text-2xl font-normal font-body leading-none;
    }
    .text-xx-large-semibold {
        @apply text-2xl font-semibold font-body leading-none;
    }
    .text-xx-large-bold {
        @apply text-2xl font-bold font-body leading-none;
    }

    .text-x-large-light {
        @apply text-xl font-light font-body leading-none;
    }
    .text-x-large-regular {
        @apply text-xl font-normal font-body leading-none;
    }
    .text-x-large-semibold {
        @apply text-xl font-semibold font-body leading-none;
    }
    .text-x-large-bold {
        @apply text-xl font-bold font-body leading-none;
    }

    .text-large-light {
        @apply text-lg font-light font-body leading-none;
    }
    .text-large-regular {
        @apply text-lg font-normal font-body leading-none;
    }
    .text-large-semibold {
        @apply text-lg font-semibold font-body leading-none;
    }
    .text-large-bold {
        @apply text-lg font-bold font-body leading-none;
    }

    .text-light {
        @apply text-base font-light font-body leading-tight;
    }
    .text-regular {
        @apply text-base font-normal font-body leading-tight;
    }
    .text-semibold {
        @apply text-base font-semibold font-body leading-tight;
    }
    .text-bold {
        @apply text-base font-bold font-body leading-tight;
    }

    .text-small-light {
        @apply text-sm font-light font-body leading-tight;
    }
    .text-small-regular {
        @apply text-sm font-normal font-body leading-tight;
    }
    .text-small-semibold {
        @apply text-sm font-semibold font-body leading-tight;
    }
    .text-small-bold {
        @apply text-sm font-bold font-body leading-tight;
    }

    .text-x-small-light {
        @apply text-xs font-light font-body leading-tight;
    }
    .text-x-small-regular {
        @apply text-xs font-normal font-body leading-tight;
    }
    .text-x-small-semibold {
        @apply text-xs font-semibold font-body leading-tight;
    }
    .text-x-small-bold {
        @apply text-xs font-bold font-body leading-tight;
    }

    .text-xx-small-regular {
        @apply text-2xs font-normal font-body leading-tight;
    }
    .text-xx-small-semibold {
        @apply text-2xs font-semibold font-body leading-tight;
    }
}