.product {
    @apply ease-in-out flex flex-col h-full items-center p-5 rounded-lg;
    
    transition: background-color 250ms;
    width: 280px;
}

.product:hover {
    @apply bg-gray-50;
}

.productImage {
    @apply aspect-auto w-full;
}

.productImageContainer {
    @apply bg-white p-5 rounded-lg;

    width: 240px;
}