.categories {
    @apply mt-5;
}

.category {
    @apply ease-in-out inline-flex items-center justify-center p-5 rounded-full;
    
    height: 120px;
    transition: transform 250ms;
    width: 120px;
}

.category:hover {
    @apply cursor-pointer;

    transform: scale(1.1);
}

.categoryLogo {
    @apply aspect-auto;

    width: 80px;
}

.container {
    @apply mx-auto w-full;

    max-width: 90%;
}

.footer {
    @apply bottom-0 sticky z-10;
}

@screen md {
    .category {
        height: 160px;
        width: 160px;
    }

    .categoryLogo {
        width: 120px;
    }
}

@screen lg {
    .category {
        height: 200px;
        width: 200px;
    }

    .categoryLogo {
        width: 160px;
    }

    .container {
        max-width: 80%;
    }
}

@media (prefers-color-scheme: dark) {
    .footer {
        @apply bg-dark-baseDarkGreen;
    }
}

@media (prefers-color-scheme: light) {
    .footer {
        @apply bg-light-baseDarkGreen;
    }
}