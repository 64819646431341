.heart {
    @apply bg-cover bg-no-repeat flex-shrink-0;
}

.ratingContainer {
    @apply flex flex-row items-center;
}

@media (prefers-color-scheme: dark) {
    .heart.dimmed {
        background-image: url("../../assets/img/icon/rating-dimmed-400.png");
    }

    .heart.lit {
        background-image: url("../../assets/img/icon/rating-lit-500.png");
    }

    .rating {
        @apply text-dark-baseRed;
    }
}

@media (prefers-color-scheme: light) {
    .heart.dimmed {
        background-image: url("../../assets/img/icon/rating-dimmed-500.png");
    }

    .heart.lit {
        background-image: url("../../assets/img/icon/rating-lit-600.png");
    }

    .rating {
        @apply text-light-baseRed;
    }
}