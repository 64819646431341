@tailwind base;
@tailwind components;
@tailwind utilities;

@import './_button.css';
@import './_type.css';

a {
    @apply ease-in-out no-underline;

    transition: color 250ms;
}

a:hover {
    @apply underline;
}

body {
    @apply antialiased;
}

body.noScroll {
    @apply overflow-y-hidden;
}

html {
    height: 100%;
}

p {
    @apply mt-4;
}

#root {
    @apply min-h-screen;
}

@media (prefers-color-scheme: dark) {
    a {
        @apply text-dark-link;
    }

    body {
        @apply bg-white text-black;
    }
}

@media (prefers-color-scheme: light) {
    a {
        @apply text-light-link;
    }

    body {
        @apply bg-white text-black;
    }
}