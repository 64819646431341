.container {
    @apply max-w-5xl mx-auto px-5 lg:px-10 w-full;
}

.icon {
    @apply ease-in-out text-gray-500;

    transition: color 250ms;
}

.icon:hover {
    @apply cursor-pointer;
}

@media (prefers-color-scheme: dark) {
    .icon:hover {
        @apply text-dark-baseRed;
    }
}

@media (prefers-color-scheme: light) {
    .icon:hover {
        @apply text-light-baseRed;
    }
}